<template>
	<!-- 预出库单 -->
	<div class="preDeliveryNote">
		<!-- 打印模板 -->
		<div :id="'export-template'+index" v-for="(item, index) in selectionList" :key="index"
			style="position: fixed;top: -999999px;">
			<div :id="'template-title' + index">
				<div style="line-height: 50px;font-size: 22px;" align=center>
					<strong>
						<font>{{ supplier_info.supplier_name }}配货单</font>
					</strong>
				</div>
				<div style="font-size: 26px;font-weight: 600;right:100px;top:10px;position: absolute;">
					{{ index + 1 }}
				</div>
				<table border=0 cellSpacing=0 cellPadding=0 width="95%" style="line-height: 20px;font-size: 14px;">
					<tbody>
						<tr>
							<td style="width:70%">
								<font>提货方式：<span>{{ item.delivery_type == 1 ?
                                '配送【' + (item.deliveryline ? item.deliveryline.title : '') + '】,运费' + item.delivery_fee +
                                '元,'
                                +
                                (item.delivery_fee_status == 0 ? '未付' : '已付')
                                : '自提' }}</span></font>
							</td>
							<td style="width:30%">
								<font>配货单号：<span>{{ item.sn }}</span></font>
							</td>
						</tr>
						<tr>
							<td style="width:70%">
								<font>配货日期：<span>{{ item.date }}</span></font>
							</td>
							<td style="width:30%">
								<font>配货仓库：<span>{{ item.warehouse ? item.warehouse.name : '暂无' }}</span></font>
							</td>
						</tr>
						<tr>

							<td style="width:70%">
								<font>收货信息：<span>{{ item.contacts }}-{{ item.phone }}-{{ item.address }}</span></font>
							</td>
							<td style="width:30%">
								<font>打印时间：<span>{{ currentDateTime }}</span></font>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div :id="'template-table' + index">
				<table border=1 style="border-collapse:collapse;width:95%" bordercolor="#333333">
					<thead>
						<tr>
							<td width="15%" style="padding:4px">
								<div align=center><b>序号</b></div>
							</td>
							<td width="55%" style="padding:4px">
								<div align=center><b>商品名称</b></div>
							</td>
							<td width="15%" style="padding:4px">
								<div align=center><b>订单数</b></div>
							</td>
							<td width="15%" style="padding:4px">
								<div align=center><b>配货数</b></div>
							</td>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(itemChil, indexChil) in item.goodsList" :key="indexChil">
							<td align=center style="padding:4px">{{ indexChil + 1 }}</td>
							<td align=center style="padding:4px">{{ itemChil.goods_name }}</td>
							<td align=center style="padding:4px">{{ itemChil.count }}</td>
							<td align=center style="padding:4px">{{ itemChil.ck_count }}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<!-- 打印模板 -->
		<div :id="'print-template'+index" v-for="(item, index) in selectionList" :key="index"
			style="position: fixed;top: -999999px;">
			<div :id="'template-title' + index">
				<div style="line-height: 50px;font-size: 22px;" align=center>
					<strong>
						<font>{{ supplier_info.supplier_name }}配货单</font>
					</strong>
				</div>
				<div style="font-size: 26px;font-weight: 600;right:100px;top:10px;position: absolute;">
					{{ index + 1 }}
				</div>
				<table border=0 cellSpacing=0 cellPadding=0 width="95%" style="line-height: 20px;font-size: 14px;">
					<tbody>
						<tr>
							<td style="width:70%">
								<font>提货方式：<span>{{ item.delivery_type == 1 ?
		                        '配送【' + (item.deliveryline ? item.deliveryline.title : '') + '】,运费' + item.delivery_fee +
		                        '元,'
		                        +
		                        (item.delivery_fee_status == 0 ? '未付' : '已付')
		                        : '自提' }}</span></font>
							</td>
							<td style="width:30%">
								<font>配货单号：<span>{{ item.sn }}</span></font>
							</td>
						</tr>
						<tr>
							<td style="width:70%">
								<font>配货日期：<span>{{ item.date }}</span></font>
							</td>
							<td style="width:30%">
								<font>配货仓库：<span>{{ item.warehouse ? item.warehouse.name : '暂无' }}</span></font>
							</td>
						</tr>
						<tr>

							<td style="width:70%">
								<font>收货信息：<span>{{ item.contacts }}-{{ item.phone }}-{{ item.address }}</span></font>
							</td>
							<td style="width:30%">
								<font>打印时间：<span>{{ currentDateTime }}</span></font>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div :id="'template-table' + index">
				<table border=1 style="border-collapse:collapse;width:95%" bordercolor="#333333">
					<thead>
						<tr style="font-size:14px;">
							<td width="10%" style="padding:4px">
								<div align=center><b>序号</b></div>
							</td>
							<td width="40%" style="padding:4px">
								<div align=center><b>商品名称</b></div>
							</td>
							<td width="10%" style="padding:4px">
								<div align=center><b>订单数</b></div>
							</td>
							<td width="15%" style="padding:4px">
								<div align=center><b>配货数</b></div>
							</td>
							<td width="10%" style="padding:4px">
								<div align=center><b>订单价</b></div>
							</td>
							<td width="15%" style="padding:4px">
								<div align=center><b>订单金额</b></div>
							</td>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(itemChil, indexChil) in item.goodsList" :key="indexChil">
							<td align=center style="padding:4px">{{ indexChil + 1 }}</td>
							<td align=center style="padding:4px">{{ itemChil.goods_name }}</td>
							<td align=center style="padding:4px">{{ itemChil.count }}</td>
							<td align=center style="padding:4px">{{ itemChil.ck_count }}</td>
							<td align=center style="padding:4px">{{ itemChil.price }}</td>
							<td align=center style="padding:4px">{{ itemChil.amount }}</td>
						</tr>
					</tbody>
					<tfoot>
						<tr style="font-size:14px;">
							<td align="center" colspan="2">
								总计:￥ {{ item.goods_amount }} 元
							</td>

							<td align="center">
								<span tdata='pageNO'>##</span>
								<span> / </span>
								<span tdata='pageCount'>##页</span>
							</td>
							<td align="center" tdata="subSum" format="0.00" colspan="3" tindex="6">
								本页小计: ￥ ### 元
							</td>
						</tr>
					</tfoot>
				</table>
			</div>
		</div>
		<div class="search">
			<div class="search-l">
				<div class="l-item" style="width: 40%;">
					<div class="item-label">配货日期:</div>
					<div class="item-input" style="width: 80%;">
						<el-date-picker v-model="dateVal" type="daterange" range-separator="至" start-placeholder="开始日期"
							end-placeholder="结束日期" value-format="yyyy-MM-dd" style="width: 100%;">
						</el-date-picker>
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">配货仓库:</div>
					<div class="item-input">
						<el-select v-model="searchForm.warehouse_id" placeholder="请选择" style="width: 100%;">
							<el-option v-for="item in warehouseOptions" :key="item.id" :label="item.name"
								:value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">配送路线:</div>
					<div class="item-input">
						<el-select v-model="searchForm.delivery_line_id" placeholder="请选择"
							@focus="routeType = 1, getRouteList()" style="width: 100%;">
							<el-option v-for="item in routeList" :key="item.id" :label="item.title" :value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">出库单号:</div>
					<div class="item-input">
						<el-input v-model="searchForm.sn" placeholder="请输入预出库单号" />
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">联系人:</div>
					<div class="item-input">
						<el-input v-model="searchForm.contacts" placeholder="请输入联系人姓名" />
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">联系电话:</div>
					<div class="item-input">
						<el-input v-model="searchForm.phone" placeholder="请输入联系电话" />
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">地址:</div>
					<div class="item-input">
						<el-input v-model="searchForm.address" placeholder="请输入地址" />
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">配货状态:</div>
					<div class="item-input">
						<el-select v-model="searchForm.status" placeholder="请选择状态" @change="searchForm.status = $event"
							style="width: 100%;">
							<el-option v-for="item in statusType" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">提货方式:</div>
					<div class="item-input">
						<el-select v-model="searchForm.delivery_type" placeholder="请选择提货方式" style="width: 100%;">
							<el-option v-for="item in deliveryTypeList" :key="item.id" :label="item.name"
								:value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">售后:</div>
					<div class="item-input">
						<el-select v-model="searchForm.has_service" placeholder="请选择是否包含售后"
							@change="searchForm.has_service = $event" style="width: 100%;">
							<el-option v-for="item in hasServiceType" :key="item.id" :label="item.name"
								:value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">包含商品:</div>
					<div class="item-input">
						<el-input v-model="searchForm.goods_name" placeholder="请输入包含商品的名称关键字" />
					</div>
				</div>
				<div class="l-item">
					<el-button type="warning" @click.stop="handleSearch">搜索</el-button>
					<el-button
						@click.stop="pageNum = 10, searchForm = {}, dateVal = [getCurrentDate(),getCurrentDate()],handleSearch()">重置</el-button>
				</div>
				<i></i>
				<i></i>
				<i></i>
				<i></i>
			</div>
		</div>
		<div class="table">
			<div class="table-operation">
				<el-button type="primary" @click.stop="orderDialogVisible = true">生成配送单</el-button>
				<el-select v-model="pageType" placeholder="打印纸" @change="selectPageType" style="margin-right: 10px;">
					<el-option v-for="item in pageTypeList" :key="item.id" :label="item.name" :value="item.id">
					</el-option>
				</el-select>
				<el-select v-model="printType" placeholder="打印类型" @change="selectPrintType"
					style="margin-right: 10px;width:120px">
					<el-option v-for="item in printTypeList" :key="item.id" :label="item.name" :value="item.id">
					</el-option>
				</el-select>
				<el-button @click.stop="handlePrint" :disabled="exportDisabled">批量打印</el-button>
				<el-button type="success" @click.stop="handleExport">导出</el-button>
				<el-button type="success" @click.stop="handleExportSummary"
					:disabled="operationDisabled">导出商品汇总</el-button>
				<el-button type="warning" @click.stop="handleOperation(1)"
					:disabled="operationDisabled">批量确认</el-button>
				<el-button type="danger" @click.stop="handleOperation(9)" :disabled="operationDisabled">批量取消</el-button>
				<el-button type="info" icon="el-icon-refresh-left" @click.stop="loading = true, getList()"></el-button>
			</div>
			<Table :data="tableData" :total="total" v-loading="loading"
				@size-change="pageNum = $event, loading = true, getList()"
				@current-change="page = $event, loading = true, getList()" @selection-change="selectionChange">
				<el-table-column type="expand">
					<template slot-scope="props">
						<el-table :data="props.row.goodsList"
							:header-cell-style="{fontSize: '14px', backgroundColor: '#f9f9f9',color:'#333'}"
							:cell-style="{ backgroundColor: '#f9f9f9'}" style="width: 100%;border:1px solid #e6a23c">
							<el-table-column prop="goods_name" label="商品名称" align="center">
								<template slot-scope="scope">
									<span
										v-if="searchForm.goods_name && scope.row.goods_name.indexOf(searchForm.goods_name)>=0"
										style="color:#5500ff;font-weight: 600;">{{ scope.row.goods_name }}</span>
									<span v-else>{{ scope.row.goods_name }}</span>
								</template>
							</el-table-column>
							<el-table-column label="商品主图" align="center">
								<template slot-scope="scope">
									<el-image style="width: 50px; height: 50px" :src="scope.row.goods_thumb"
										:preview-src-list="[scope.row.goods_pic]">
									</el-image>
								</template>
							</el-table-column>
							<el-table-column label="状态" align="center">
								<template slot-scope="scope">
									<span v-if="scope.row.status == 0">待确认</span>
									<span v-if="scope.row.status == 1">已确认</span>
									<span v-if="scope.row.status == 2">已延期</span>
									<span v-if="scope.row.status == 9">已取消</span>
								</template>
							</el-table-column>
							<el-table-column prop="price" label="订单价" align="center"></el-table-column>
							<el-table-column prop="count" label="订单数量" align="center">
								<template slot-scope="scope">
									<span style="color:#67C23A;font-weight: 600;">{{ scope.row.count }}</span>
								</template>
							</el-table-column>
							<el-table-column prop="amount" label="订单金额" align="center"></el-table-column>
							<el-table-column prop="ck_count" label="配送数量" align="center">
								<template slot-scope="scope">
									<span style="color:#409EFF;font-weight: 600;">{{ scope.row.ck_count }}</span>
								</template>
							</el-table-column>
							<el-table-column prop="ck_amount" label="配送金额" align="center"></el-table-column>
							<el-table-column prop="count" label="售后状态" align="center">
								<template slot-scope="scope">
									<span style="color:#F56C6C;font-weight: 600;"
										v-if="scope.row.has_service == 1">关联订单有售后，可通过联系人查询订单</span>
								</template>
							</el-table-column>
							<el-table-column prop="sys_remark" label="说明" align="center"></el-table-column>
							<el-table-column label="操作" align="center" width="260">
								<template slot-scope="scope">
									<el-button type="primary" size="mini" v-if="scope.row.status == 0"
										@click.stop="dialogVisibleType = 2, dialogVisible = true, listItem = scope.row">延期</el-button>
									<el-button type="danger" size="mini" v-if="scope.row.status == 0"
										@click.stop="handleGoodsStatus(scope.row, 9)">取消</el-button>
								</template>
							</el-table-column>
						</el-table>
					</template>
				</el-table-column>
				<el-table-column type="selection" width="55"></el-table-column>
				<el-table-column label="状态" align="center" width="80">
					<template slot-scope="scope">
						<span v-if="scope.row.status == 0" style="color:#303133">待确认</span>
						<span v-if="scope.row.status == 1" style="color:#67C23A">已确认</span>
						<span v-if="scope.row.status == 2">已延期</span>
						<span v-if="scope.row.status == 9" style="color:#909399">已取消</span>
					</template>
				</el-table-column>

				<el-table-column label="配送单号" align="center" width="190">
					<template slot-scope="scope">
						<span v-if="scope.row.type == 1">{{scope.row.sn}}</span>
						<span v-if="scope.row.type == 2" style="color:red">{{scope.row.sn}}[延期创建]</span>
					</template>
				</el-table-column>
				<el-table-column label="配送日期" prop="date" align="center" width="100"></el-table-column>
				<el-table-column label="订单数" prop="purorder_count" align="center" width="80"></el-table-column>
				<el-table-column label="配货仓库" prop="warehouse.name" align="center" width="120"></el-table-column>
				<el-table-column label="订单金额" prop="purorder_amount" align="center" width="100"></el-table-column>
				<el-table-column label="配送方式" align="center" width="160">
					<template slot-scope="scope">
						<span v-if="scope.row.delivery_type == 2">自提[{{scope.row.warehouse.name}}]</span>
						<span v-if="scope.row.delivery_type == 1"
							style="color:#E6A23C">配送<br>仓库：{{scope.row.warehouse.name}}<br>路线：<span
								v-if="scope.row.deliveryline">{{ scope.row.deliveryline.title }}</span><span
								v-else>未设置</span>
						</span>
					</template>

				</el-table-column>
				<el-table-column label="运费" align="center" width="100">
					<template slot-scope="scope">
						<span>{{ scope.row.delivery_fee }}[{{ scope.row.delivery_fee_status == 1 ? '已付' : '未付' }}]</span>
					</template>
				</el-table-column>
				<el-table-column label="下单团长" align="center" width="120">
					<template slot-scope="scope">
						<p>{{ scope.row.leader.username }}</p>
						<p> {{ scope.row.leader.phone }}</p>
					</template>
				</el-table-column>
				<el-table-column label="收货人" align="center">
					<template slot-scope="scope">
						<p>{{ scope.row.contacts }}</p>
						<p>{{ scope.row.phone }}</p>
						<p>{{ scope.row.address }}</p>
					</template>
				</el-table-column>
				<el-table-column label="生成时间" prop="exec_date" align="center" width="160"></el-table-column>
				<el-table-column label="创建时间" prop="created_at" align="center" width="160"></el-table-column>
				<el-table-column label="操作" align="center" width="460">
					<template slot-scope="scope">
						<el-button type="success" size="mini" v-if="scope.row.status == 1"
							@click.stop="handlePrint(scope.row)">打印</el-button>
						<el-button type="primary" size="mini" v-if="scope.row.status == 0"
							@click.stop="dialogVisibleType = 1, dialogVisible = true, listItem = scope.row">整单延期</el-button>
						<el-button type="warning" size="mini" v-if="scope.row.status == 0"
							@click.stop="handleStatus(scope.row, 1)">确认</el-button>
						<el-button type="danger" size="mini" v-if="scope.row.status == 0"
							@click.stop="handleStatus(scope.row, 9)">取消</el-button>
						<el-button type="warning" size="mini" v-if="scope.row.status == 0"
							@click.stop="handleDeliveryDialog(scope.row)">配送方式</el-button>
						<el-button size="mini" @click.stop="handleAddress(scope.row)">改地址</el-button>
					</template>
				</el-table-column>
			</Table>
		</div>
		<!-- 修改地址 -->
		<el-dialog title="修改地址" :visible.sync="addressDialog" :close-on-click-modal="false" v-el-drag-dialog
			width="30%">
			<el-form ref="form" :model="addressForm" label-width="80px">
				<el-form-item label="收货人">
					<el-input v-model="addressForm.contacts" placeholder="请输入收货人" />
				</el-form-item>
				<el-form-item label="联系电话">
					<el-input v-model="addressForm.phone" placeholder="请输入联系电话" />
				</el-form-item>
				<el-form-item label="详细地址">
					<el-input v-model="addressForm.address" placeholder="请输入详细地址" />
				</el-form-item>
				<el-form-item label="配送路线" v-if="addressForm.delivery_type == 1">
					<el-select v-model="addressForm.delivery_line_id" placeholder="请选择配送路线" style="width: 100%;"
						@focus="getRouteList()">
						<el-option v-for="item in addressRouteLine" :key="item.id" :label="item.title" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click.stop="addressDialog = false">取 消</el-button>
				<el-button type="primary" @click.stop="addressConfirmFn" v-loading.fullscreen.lock="loading">确
					定</el-button>
			</span>
		</el-dialog>
		<!-- 生成配送单 -->
		<el-dialog title="生成配送单" :visible.sync="orderDialogVisible" :close-on-click-modal="false" v-el-drag-dialog
			width="30%">
			<el-form ref="form" label-width="100px">
				<el-form-item label="日期">
					<el-date-picker v-model="orderDate" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"
						:picker-options="pickerOptions" style="width:100%">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="订单核销">
					<el-radio-group v-model="check_order">
						<el-radio :label="0">不核销</el-radio>
						<el-radio :label="1">自动核销配送日未核销订单</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="">
					<p>*只生成状态正常的未配商品的配货单，不在配送日、退款及申请售后的订单商品不会生成</p>
					<p>*请及时核销销售订单，以免出单后客户再退款售后给您带来损失</p>
					<p>*同一配送日可多次生成，销售订单不会重复出单</p>
				</el-form-item>

			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click.stop="orderDialogVisible = false">取 消</el-button>
				<el-button type="primary" @click.stop="orderConfirmFn">确 定</el-button>
			</span>
		</el-dialog>
		<!-- 延期 -->
		<el-dialog :title="dialogVisibleType == 1 ? '整单延期' : '商品延期'" :visible.sync="dialogVisible"
			:close-on-click-modal="false" v-el-drag-dialog width="30%">
			<el-form ref="form" label-width="100px">
				<el-form-item label="延期数量" v-if="dialogVisibleType == 2">
					<el-input v-model="form.count" placeholder="请输入延期数量"></el-input>
				</el-form-item>
				<el-form-item label="预出库日期">
					<el-date-picker v-model="form.date" type="date" placeholder="选择预出库日期" value-format="yyyy-MM-dd"
						:picker-options="pickerOptions" style="width:100%">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="提货方式">
					<el-select v-model="form.delivery_type" placeholder="请选择提货方式" style="width: 100%;">
						<el-option v-for="item in deliveryType" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="配送路线" v-if="form.delivery_type == 1">
					<el-select v-model="form.delivery_line_id" placeholder="请选择配送路线" style="width: 100%;"
						@focus="routeType = '', getRouteList()">
						<el-option v-for="item in routeList" :key="item.id" :label="item.title" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="运费费用" v-if="form.delivery_type == 1">
					<el-input v-model="form.delivery_fee" placeholder="请输入运费费用"></el-input>
				</el-form-item>
				<el-form-item label="运费是否支付" v-if="form.delivery_type == 1">
					<el-select v-model="form.delivery_fee_status" placeholder="请选择运费是否支付" style="width: 100%;"
						@change="form.delivery_fee_status = $event">
						<el-option v-for="item in deliveryFeeStatus" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click.stop="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click.stop="confirmFn" v-loading.fullscreen.lock="loading">确 定</el-button>
			</span>
		</el-dialog>
		<!-- 更改配送方式 -->
		<el-dialog title="更改配送方式" :visible.sync="deliveryDialogVisible" :close-on-click-modal="false" v-el-drag-dialog
			width="30%">
			<el-form ref="form" :model="deliveryForm" label-width="120px">
				<el-form-item label="配送费支付状态">
					<el-select v-model="deliveryForm.delivery_fee_status" placeholder="请选择配送费支付状态" style="width: 100%;">
						<el-option v-for="item in deliveryStatusList" :key="item.id" :label="item.name"
							:value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="配送路线">
					<el-select v-model="deliveryForm.delivery_line_id" placeholder="请选择所属路线" style="width: 100%;"
						@focus="routeType = '', getRouteList()">
						<el-option v-for="item in routeList" :key="item.id" :label="item.title" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="提货方式">
					<el-select v-model="deliveryForm.delivery_type" placeholder="请选择提货方式" style="width: 100%;">
						<el-option v-for="item in deliveryList" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="配送费">
					<el-input v-model="deliveryForm.delivery_fee" placeholder="请输入配送费"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click.stop="deliveryDialogVisible = false">取 消</el-button>
				<el-button type="warning" @click.stop="confirmDeliveryFn">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		getLodop
	} from '@/utils/CLodopfuncs.js'
	export default {
		data() {
			return {
				dateVal: [],
				searchForm: {
					begin: '',
					end: '',
					delivery_line_id: '',
					warehouse_id: ''
				},
				warehouseOptions: [],
				tableData: [],
				loading: true,
				page: 1,
				pageNum: 10,
				total: 0,
				orderDate: this.getCurrentDate(),
				check_order: 1,
				orderDialogVisible: false,
				// 供应商信息
				supplier_info: {},
				// 状态
				statusType: [{
						id: 0,
						name: '待确认'
					},
					{
						id: 1,
						name: '已确认'
					},
					{
						id: 2,
						name: '已延期'
					},
					{
						id: 9,
						name: '取消'
					}
				],
				// 含不含售后
				hasServiceType: [{
						id: 0,
						name: '不含售后'
					},
					{
						id: 1,
						name: '有售后'
					}
				],
				// 提货方式
				deliveryTypeList: [{
						id: 1,
						name: '自提'
					},
					{
						id: 2,
						name: '配送'
					}
				],
				// 选择表格的数据
				selectionList: [],
				// 提货方式
				deliveryType: [{
						id: 1,
						name: '配送'
					},
					{
						id: 2,
						name: '自提'
					}
				],
				// 配送费支付状态
				deliveryStatusList: [{
						id: 0,
						name: '未支付'
					},
					{
						id: 1,
						name: '已支付'
					}
				],
				// 运费是否支付
				deliveryFeeStatus: [{
						id: 0,
						name: '未付'
					},
					{
						id: 1,
						name: '已付'
					}
				],
				// 编辑
				dialogVisibleType: 1,
				dialogVisible: false,
				form: {
					date: '',
				},
				// 配送方式
				deliveryForm: {},
				deliveryDialogVisible: false,
				routeList: [],
				// 是否配送
				deliveryList: [{
						id: 1,
						name: '配送'
					},
					{
						id: 2,
						name: '自提'
					}
				],
				pickerOptions: {
					disabledDate(time) {
						return time.getTime() < Date.now() - 8.64e7;
					},
				},
				exportDisabled: false, //打印禁用
				operationDisabled: false, //批量确认、取消禁用
				//修改地址
				addressForm: {},
				addressDialog: false,
				addressRouteLine: [],
				working: false,
				//打印纸
				pageType: 1,
				pageTypeItem: {
					id: 1,
					name: '针式三等分多联纸',
					width: 2410,
					height: 930
				},
				pageTypeList: [{
					id: 1,
					name: '针式三等分多联纸',
					width: 2410,
					height: 930
				}, {
					id: 2,
					name: '针式二等分多联纸',
					width: 2410,
					height: 1400
				}, {
					id: 3,
					name: '针式一等分多联纸',
					width: 2410,
					height: 2800
				}, {
					id: 4,
					name: '标准A4纸',
					width: 2100,
					height: 2970
				}],
				currentDateTime: this.getCurrentDateTime(),
				printType: 1,
				printTypeItem: {
					id: 1,
					name: '不含价格',
				},
				printTypeList: [{
					id: 1,
					name: '不含价格',
				}, {
					id: 2,
					name: '含价格',
				}]
			}
		},
		mounted() {
			this.supplier_info = JSON.parse(sessionStorage.getItem('supplier_info'))
			// 列表
			this.getList()
			// 时间
			this.dateVal = [this.getCurrentDate(), this.getCurrentDate()]
			this.searchForm.begin = this.dateVal[0]
			this.searchForm.end = this.dateVal[1]
			this.checkNoDelivery()
			this.getRouteList()
			this.getWarehouseList()
			this.pageType = localStorage.getItem("defaultPageType") ?? '1';
			this.pageType = Number(this.pageType)
			if (localStorage.getItem("defaultPageTypeItem")) {
				this.pageTypeItem = JSON.parse(localStorage.getItem("defaultPageTypeItem"));
			} else {
				this.pageTypeItem = {
					id: 1,
					name: '针式三等分多联纸',
					width: 2410,
					height: 930
				};
			}
		},
		methods: {
			handleSearch() {
				if (this.loading) return;
				this.loading = true
				this.page = 1
				this.searchForm.begin = this.dateVal[0]
				this.searchForm.end = this.dateVal[1]
				this.getList()
			},
			selectPrintType(value) {
				let that = this
				this.printTypeList.forEach(item => {
					if (item.id == value) {
						that.printTypeItem = item
					}
				})
			},
			// 仓库
			getWarehouseList() {
				this.$http.post(`erp/v1/warehouse/all`).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.warehouseOptions = data
					} else {
						this.$message.error(msg);
					}
				});
			},
			selectPageType(value) {
				let that = this
				this.pageTypeList.forEach(item => {
					if (item.id == value) {
						that.pageTypeItem = item
					}
				})
				localStorage.setItem("defaultPageType", value);
				localStorage.setItem("defaultPageTypeItem", JSON.stringify(that.pageTypeItem));
			},
			checkNoDelivery() {
				this.$http.post(`erp/v1/preck/check_no_delivery`, {}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200 && data > 0) {

						this.$alert('今日有' + data + '件商品未生成配送单，请重新生成', '警告', {
							confirmButtonText: '确定',
							callback: action => {

							}
						});
					}
				});
			},
			handleAddress(item) {
				this.addressForm = {
					contacts: item.contacts,
					phone: item.phone,
					address: item.address,
					delivery_line_id: item.delivery_line_id,
					delivery_type: item.delivery_type,
					pre_ckd_ids: item.id,
				}
				this.addressRouteLine.push({
					id: 0,
					title: "未设置",
				})
				this.addressDialog = true;
			},
			addressConfirmFn() {
				this.$http.post(`erp/v1/preck/modify_address`, {
					...this.addressForm
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.$message.success(msg)
						this.addressForm = {}
						this.addressDialog = false
						this.getList()
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 获取当前年月日
			getCurrentDate() {
				const today = new Date();
				let year = today.getFullYear();
				let month = (today.getMonth() + 1).toString().padStart(2, '0');
				let day = today.getDate().toString().padStart(2, '0');
				return `${year}-${month}-${day}`;
			},
			getList() {
				this.$http.post(`erp/v1/preck/lst`, {
					page: this.page,
					pageNum: this.pageNum,
					...this.searchForm
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.tableData = data.rows
						this.total = data.total
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 选择表格数据
			selectionChange(e) {
				this.selectionList = e
				// // 打印
				// const exportArr = this.selectionList.every(i => {
				//     return i.status === 1
				// })
				// if (exportArr) {
				//     this.exportDisabled = false
				// } else {
				//     this.exportDisabled = true
				// }
				// 批量确认、取消
				const operationArr = this.selectionList.every(i => {
					return i.status === 0
				})
				if (operationArr) {
					this.operationDisabled = false
				} else {
					this.operationDisabled = true
				}
			},
			// 生成预出库单号
			orderConfirmFn() {
				if (!this.orderDate) {
					this.$message({
						type: 'error',
						message: '请选择日期'
					});
					return
				}
				if (this.working) return;
				this.working = true
				this.loading = true
				this.$http.post(`erp/v1/preck/generate`, {
					date: this.orderDate,
					check_order: this.check_order
				}).then((res) => {
					this.working = false
					this.loading = false
					const {
						code,
						data,
						msg
					} = res.data
					if (code == 200) {
						this.$message.success(msg);
						this.dateVal = [this.orderDate, this.orderDate]
						this.searchForm.begin = this.orderDate
						this.searchForm.end = this.orderDate
						this.getList()
						this.orderDialogVisible = false
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 打印
			handlePrint(item) {
				if (item.sn) {
					this.selectionList = []
					this.selectionList.push(item)
				} else {
					if (this.selectionList.length <= 0) {
						this.$message.error('请选择数据');
						return
					}
				}
				let that = this
				setTimeout(() => {
					var LODOP = getLodop();
					if (that.pageType != 4) {
						var tableTop = 140
					} else {
						var tableTop = 160

					}
					var totalPrint = 0
					for (var i = 0; i < this.selectionList.length; i++) {
						totalPrint++
						if (that.printType == 1) {
							var parentDiv = document.getElementById('export-template' + i);
						} else {
							var parentDiv = document.getElementById('print-template' + i);
						}

						var strStyle =
							"<style> table,td,th {border-width: 1px;border-style: solid;border-collapse: collapse}</style>"

						LODOP.SET_PRINT_PAGESIZE(0, this.pageTypeItem.width, this.pageTypeItem
							.height);
						/* 						LODOP.ADD_PRINT_TABLE(tableTop, "5%", "90%", "100%",
													strStyle + parentDiv
													.querySelector("#template-table" + i).innerHTML);
												LODOP.ADD_PRINT_HTM(2, "5%", "90%", tableTop, parentDiv
													.querySelector(
														"#template-title" + i).innerHTML); */
						LODOP.ADD_PRINT_TABLE(tableTop, 20, "RightMargin:20mm", "BottomMargin:20",
							strStyle + parentDiv
							.querySelector("#template-table" + i).innerHTML);
						LODOP.ADD_PRINT_HTM(2, 20, "RightMargin:20mm", "BottomMargin:20", parentDiv
							.querySelector(
								"#template-title" + i).innerHTML);
						LODOP.SET_PRINT_STYLEA(0, "ItemType", 1);
						LODOP.SET_PRINT_STYLEA(0, "LinkedItem", -1);
						LODOP.NewPageA();
						if (totalPrint == this.selectionList.length) {
							LODOP.PREVIEW()
						}
					}
					this.getList()
					this.selectionList = []
				}, 500);
			},
			// 导出
			handleExport() {
				this.$http.post(`erp/v1/preck/lst`, {
					page: this.page,
					pageNum: this.pageNum,
					...this.searchForm,
					export: 1
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						window.open(data.url, '_self')
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 导出商品汇总
			handleExportSummary() {
				let ckdIds = ""
				if (this.selectionList.length > 0) {
					this.selectionList.forEach(i => {
						ckdIds = ckdIds + i.id + ","
					})
				}
				this.$http.post(`erp/v1/preck/summary`, {
					page: this.page,
					pageNum: this.pageNum,
					...this.searchForm,
					pre_ckd_ids: ckdIds,
					export: 1
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						window.open(data.url, '_self')
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 批量确认、取消
			handleOperation(type) {
				if (this.selectionList.length <= 0) {
					this.$message({
						type: 'error',
						message: '请选择数据'
					});
					return
				}
				let idArr = []
				this.selectionList.forEach(item => {
					idArr.push(item.id)
				});
				this.$confirm('批量操作该数据吗, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http.post(`/erp/v1/preck/status`, {
						id: idArr.join(','),
						status: type
					}).then((res) => {
						const {
							code,
							data,
							msg
						} = res.data
						if (code == 200) {
							this.getList()
							this.$message.success(msg);
						} else {
							this.$message.error(msg);
						}
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
			// 确认/取消
			handleStatus(item, type) {
				this.$confirm('操作该数据吗, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http.post(`/erp/v1/preck/status`, {
						id: item.id,
						status: type
					}).then((res) => {
						const {
							code,
							data,
							msg
						} = res.data
						if (code == 200) {
							this.getList()
							this.$message.success(msg);
						} else {
							this.$message.error(msg);
						}
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消操作'
					});
				});
			},
			// 确认/取消
			handleGoodsStatus(item, type) {
				this.$confirm('操作该数据吗, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http.post(`/erp/v1/preck/goods_status`, {
						id: item.id,
						status: type
					}).then((res) => {
						const {
							code,
							data,
							msg
						} = res.data
						if (code == 200) {
							this.getList()
							this.$message.success(msg);
						} else {
							this.$message.error(msg);
						}
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消操作'
					});
				});
			},
			// 所属路线
			getRouteList() {
				this.$http.post(`erp/v1/delivery_line/all`).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					if (code == 200) {
						this.addressRouteLine = data
						this.routeList = data
						if (this.routeType == 1) {
							this.routeList.unshift({
								delivery_line_id: 0,
								title: '未设置'
							})
						}
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 配送方式弹窗
			handleDeliveryDialog(item) {
				this.deliveryDialogVisible = true
				this.deliveryForm = {
					id: item.id,
					delivery_line_id: '',
					delivery_fee_status: item.delivery_fee_status,
					delivery_type: item.delivery_type,
					delivery_fee: item.delivery_fee,
				}
				console.log(this.deliveryForm, ' this.deliveryForm');
			},
			// 修改配送方式
			confirmDeliveryFn() {
				if (!this.deliveryForm.delivery_line_id) {
					this.$message.error('请选择所属路线');
					return
				}
				this.$http.post(`erp/v1/preck/set`, {
					...this.deliveryForm
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					if (code == 200) {
						this.deliveryDialogVisible = false
						this.$message.success(msg);
						this.getList()
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 延期确定
			confirmFn() {
				if (this.form.delivery_type == 2) {
					this.form.delivery_line_id == ''
					this.form.delivery_fee == ''
					this.form.delivery_fee_status == ''
				}
				let url = this.dialogVisibleType == 1 ? '/erp/v1/preck/order_delay' : '/erp/v1/preck/goods_delay'
				if (this.dialogVisibleType == 1) {
					var info = {
						id: this.listItem.id,
						...this.form
					}
				} else {
					var info = {
						pre_ckd_mx_id: this.listItem.id,
						...this.form
					}
				}
				this.$http.post(`${url}`, info).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					if (code == 200) {
						this.getList()
						this.$message.success(msg);
						this.dialogVisible = false
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 获取当前年月日时分秒
			getCurrentDateTime() {
				const now = new Date();
				const year = now.getFullYear();
				const month = this.padNumber(now.getMonth() + 1); // 月份是从0开始的
				const day = this.padNumber(now.getDate());
				const hours = this.padNumber(now.getHours());
				const minutes = this.padNumber(now.getMinutes());
				const seconds = this.padNumber(now.getSeconds());
				return (
					year +
					'-' +
					month +
					'-' +
					day +
					' ' +
					hours +
					':' +
					minutes +
					':' +
					seconds
				);
			},
			padNumber(num) {
				return num < 10 ? '0' + num : num;
			},
		},
	}
</script>

<style lang="scss" scoped>
	.export-template {
		#template-title {
			.title-t {
				text-align: center;
				margin-bottom: 20px;
				font-weight: bold;
				font-size: 22px;

			}

			.title-box {
				display: flex;
				flex-wrap: wrap;

				.box-item {
					width: 50%;
					display: flex;
					justify-content: center;
					margin-bottom: 20px;

					.item-i {
						width: 60%;

						span {
							padding-right: 20px;
						}
					}
				}
			}
		}
	}

	.page {
		margin-top: 20px;
		text-align: right;
	}
</style>